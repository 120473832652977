@import "~@angular/material/prebuilt-themes/deeppurple-amber.css";

@import 'styles/scrollbar.scss';

@import 'styles/sentry-custom.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'roboto-mono';
    src: url('assets/roboto-mono.woff2') format('woff2'),
        url('assets/roboto-mono.woff') format('woff'),
        url('assets/roboto-mono.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kanit Regular';
    font-display: swap;
    src:
        url('assets/Kanit-Regular.woff2') format('woff2 supports variations'),
        url('assets/Kanit-Regular.woff2') format('woff2-variations');
    font-weight: 200 900;
    font-stretch: 75% 125%;
}

vscode-text-area {
    &::part(control) {
        @apply font-mono;
    }
}

:root {
    font-size: var(--vscode-font-size);
}

body {

    @apply absolute w-full h-full;
    @apply text-vscode-editor-foreground;

    /* Disable all selection */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;

    // Since the graph editor isn't scrollable
    // at all, disable any overscroll behaviour
    // to avoid any weird UX behaviour.
    //
    // Addendum: There is one scenario where a scrollbar might accidentally appear.
    // When a node preview tooltip in the right node-menu is shown, and the user scrolls up in the list,
    // the tooltip follows the scroll to the bottom until it leaves the viewport. This causes the page height 
    // to temporarily increase, resulting in a scrollbar appearing for a few milliseconds 
    // until the tooltip disappears.
    overscroll-behavior-y: none;
    overflow-y: hidden;
}

// Most input fields already have a focus style.
// Especially in Firefox the outline is visible
// on top of the focus border.
input:focus,
textarea:focus,
vscode-dropdown:focus {
    outline: none;
}

a {
    color: var(--vscode-editor-foreground);
}

.custom-secret,
textarea,
input[type='text'],
input[type='number'] {
    background: var(--vscode-input-background);
    color: var(--vscode-input-foreground);
    font-size: var(--vscode-font-size);
    border-width: 1px;
    border-color: transparent;

    @apply p-1;

    &::placeholder {
        color: var(--vscode-input-placeholderForeground);
    }

    &:focus {
        border-color: var(--vscode-focusBorder);
    }

}

.custom-bg-gradient {
    background-image: var(--custom-bg-gradient);
}

::ng-deep .custom-modal-dialog {
    --mdc-dialog-container-color: var(--vscode-editor-background);
    --mdc-dialog-supporting-text-color: var(--vscode-editor-foreground);

    background: var(--vscode-editor-background);
}

.animate-pulse-extrem {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {

        0%,
        100% {
            opacity: 1;
        }

        50% {
            opacity: .2;
        }
    }
}

.animate-glow-and-fade {
    animation: glow 250ms linear forwards;

    @keyframes glow {
        0% {
            filter: drop-shadow(0 0 10px rgba(0, 255, 0, 0));
        }

        10% {
            filter: drop-shadow(0 0 10px rgba(0, 255, 0, 1));
        }

        100% {
            filter: drop-shadow(0 0 20px rgba(0, 255, 0, 0.01));
        }
    }
}

ng-icon {
    @apply text-2xl;
}

app-root {
    // The graph layout uses the column flex style
    // to fill the remaining space vertically.
    flex-direction: column;
    height: 100%;
    display: flex;
}

// unset the filter that is somehow used to intensify the boxshadow
.mat-mdc-menu-panel.mat-mdc-menu-panel.tour-step {
    filter: unset !important
}

.tippy-content {
    @apply font-mono;
}



#lasso {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    polygon {
        stroke: #6c7fe9ad;
        stroke-dasharray: 10 5;
        stroke-width: 2;
        fill: rgb(144 161 255 / 21%);
        z-index: 5;
        animation: dash linear 1s infinite;
    }
}

@keyframes dash {
    to {
        stroke-dashoffset: 15;
    }
}