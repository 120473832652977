.custom-scrollbar {
    position: relative;
    overflow-y: auto;
    scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.custom-scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: var(--vscode-scrollbarSlider-background);
    transition: background-color .8s linear;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: var(--vscode-scrollbarSlider-hoverBackground);
}

.custom-scrollbar::-webkit-scrollbar-thumb:active {
    background-color: var(--vscode-scrollbarSlider-activeBackground);
}