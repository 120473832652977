html.dark {
    --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    --vscode-font-weight: normal;
    --vscode-font-size: 16px;
    --vscode-editor-font-family: Menlo, Monaco, 'Courier New', monospace;
    --vscode-editor-font-weight: normal;
    --vscode-editor-font-size: 12px;
    --vscode-foreground: #cccccc;
    --vscode-disabledForeground: rgba(204, 204, 204, 0.5);
    --vscode-errorForeground: #f48771;
    --vscode-descriptionForeground: rgba(204, 204, 204, 0.7);
    --vscode-icon-foreground: #c5c5c5;
    --vscode-focusBorder: #007fd4;
    --vscode-textSeparator-foreground: rgba(255, 255, 255, 0.18);
    --vscode-textLink-foreground: #3794ff;
    --vscode-textLink-activeForeground: #3794ff;
    --vscode-textPreformat-foreground: #d7ba7d;
    --vscode-textPreformat-background: rgba(255, 255, 255, 0.1);
    --vscode-textBlockQuote-background: #222222;
    --vscode-textBlockQuote-border: rgba(0, 122, 204, 0.5);
    --vscode-textCodeBlock-background: rgba(10, 10, 10, 0.4);
    --vscode-widget-shadow: rgba(0, 0, 0, 0.36);
    --vscode-widget-border: #303031;
    --vscode-input-background: #3c3c3c;
    --vscode-input-foreground: #cccccc;
    --vscode-inputOption-activeBorder: #007acc;
    --vscode-inputOption-hoverBackground: rgba(90, 93, 94, 0.5);
    --vscode-inputOption-activeBackground: rgba(0, 127, 212, 0.4);
    --vscode-inputOption-activeForeground: #ffffff;
    --vscode-input-placeholderForeground: #a6a6a6;
    --vscode-inputValidation-infoBackground: #063b49;
    --vscode-inputValidation-infoBorder: #007acc;
    --vscode-inputValidation-warningBackground: #352a05;
    --vscode-inputValidation-warningBorder: #b89500;
    --vscode-inputValidation-errorBackground: #5a1d1d;
    --vscode-inputValidation-errorBorder: #be1100;
    --vscode-dropdown-background: #3c3c3c;
    --vscode-dropdown-foreground: #f0f0f0;
    --vscode-dropdown-border: #3c3c3c;
    --vscode-button-foreground: #ffffff;
    --vscode-button-separator: rgba(255, 255, 255, 0.4);
    --vscode-button-background: #0e639c;
    --vscode-button-hoverBackground: #1177bb;
    --vscode-button-secondaryForeground: #ffffff;
    --vscode-button-secondaryBackground: #3a3d41;
    --vscode-button-secondaryHoverBackground: #45494e;
    --vscode-badge-background: #4d4d4d;
    --vscode-badge-foreground: #ffffff;
    --vscode-scrollbar-shadow: #000000;
    --vscode-scrollbarSlider-background: rgba(121, 121, 121, 0.4);
    --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    --vscode-scrollbarSlider-activeBackground: rgba(191, 191, 191, 0.4);
    --vscode-progressBar-background: #0e70c0;
    --vscode-editorError-foreground: #f14c4c;
    --vscode-editorWarning-foreground: #cca700;
    --vscode-editorInfo-foreground: #3794ff;
    --vscode-editorHint-foreground: rgba(238, 238, 238, 0.7);
    --vscode-sash-hoverBorder: #007fd4;
    --vscode-editor-background: #1e1e1e;
    --vscode-editor-foreground: #d4d4d4;
    --vscode-editorStickyScroll-background: #1e1e1e;
    --vscode-editorStickyScrollHover-background: #2a2d2e;
    --vscode-editorWidget-background: #252526;
    --vscode-editorWidget-foreground: #cccccc;
    --vscode-editorWidget-border: #454545;
    --vscode-quickInput-background: #252526;
    --vscode-quickInput-foreground: #cccccc;
    --vscode-quickInputTitle-background: rgba(255, 255, 255, 0.1);
    --vscode-pickerGroup-foreground: #3794ff;
    --vscode-pickerGroup-border: #3f3f46;
    --vscode-keybindingLabel-background: rgba(128, 128, 128, 0.17);
    --vscode-keybindingLabel-foreground: #cccccc;
    --vscode-keybindingLabel-border: rgba(51, 51, 51, 0.6);
    --vscode-keybindingLabel-bottomBorder: rgba(68, 68, 68, 0.6);
    --vscode-editor-selectionBackground: #264f78;
    --vscode-editor-inactiveSelectionBackground: #3a3d41;
    --vscode-editor-selectionHighlightBackground: rgba(173, 214, 255, 0.15);
    --vscode-editor-findMatchBackground: #515c6a;
    --vscode-editor-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
    --vscode-editor-findRangeHighlightBackground: rgba(58, 61, 65, 0.4);
    --vscode-searchEditor-findMatchBackground: rgba(234, 92, 0, 0.22);
    --vscode-search-resultsInfoForeground: rgba(204, 204, 204, 0.65);
    --vscode-editor-hoverHighlightBackground: rgba(38, 79, 120, 0.25);
    --vscode-editorHoverWidget-background: #252526;
    --vscode-editorHoverWidget-foreground: #cccccc;
    --vscode-editorHoverWidget-border: #454545;
    --vscode-editorHoverWidget-statusBarBackground: #2c2c2d;
    --vscode-editorLink-activeForeground: #4e94ce;
    --vscode-editorInlayHint-foreground: #969696;
    --vscode-editorInlayHint-background: rgba(77, 77, 77, 0.1);
    --vscode-editorInlayHint-typeForeground: #969696;
    --vscode-editorInlayHint-typeBackground: rgba(77, 77, 77, 0.1);
    --vscode-editorInlayHint-parameterForeground: #969696;
    --vscode-editorInlayHint-parameterBackground: rgba(77, 77, 77, 0.1);
    --vscode-editorLightBulb-foreground: #ffcc00;
    --vscode-editorLightBulbAutoFix-foreground: #75beff;
    --vscode-diffEditor-insertedTextBackground: rgba(156, 204, 44, 0.2);
    --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.2);
    --vscode-diffEditor-insertedLineBackground: rgba(155, 185, 85, 0.2);
    --vscode-diffEditor-removedLineBackground: rgba(255, 0, 0, 0.2);
    --vscode-diffEditor-diagonalFill: rgba(204, 204, 204, 0.2);
    --vscode-diffEditor-unchangedRegionBackground: #3e3e3e;
    --vscode-diffEditor-unchangedRegionForeground: #a3a2a2;
    --vscode-diffEditor-unchangedCodeBackground: rgba(116, 116, 116, 0.16);
    --vscode-list-focusOutline: #007fd4;
    --vscode-list-activeSelectionBackground: #04395e;
    --vscode-list-activeSelectionForeground: #ffffff;
    --vscode-list-activeSelectionIconForeground: #ffffff;
    --vscode-list-inactiveSelectionBackground: #37373d;
    --vscode-list-hoverBackground: #2a2d2e;
    --vscode-list-dropBackground: #383b3d;
    --vscode-list-highlightForeground: #2aaaff;
    --vscode-list-focusHighlightForeground: #2aaaff;
    --vscode-list-invalidItemForeground: #b89500;
    --vscode-list-errorForeground: #f88070;
    --vscode-list-warningForeground: #cca700;
    --vscode-listFilterWidget-background: #252526;
    --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
    --vscode-listFilterWidget-noMatchesOutline: #be1100;
    --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.36);
    --vscode-list-filterMatchBackground: rgba(234, 92, 0, 0.33);
    --vscode-tree-indentGuidesStroke: #585858;
    --vscode-tree-inactiveIndentGuidesStroke: rgba(88, 88, 88, 0.4);
    --vscode-tree-tableColumnsBorder: rgba(204, 204, 204, 0.13);
    --vscode-tree-tableOddRowsBackground: rgba(204, 204, 204, 0.04);
    --vscode-list-deemphasizedForeground: #8c8c8c;
    --vscode-checkbox-background: #3c3c3c;
    --vscode-checkbox-selectBackground: #252526;
    --vscode-checkbox-foreground: #f0f0f0;
    --vscode-checkbox-border: #6b6b6b;
    --vscode-checkbox-selectBorder: #c5c5c5;
    --vscode-quickInputList-focusForeground: #ffffff;
    --vscode-quickInputList-focusIconForeground: #ffffff;
    --vscode-quickInputList-focusBackground: #04395e;
    --vscode-menu-border: #454545;
    --vscode-menu-foreground: #cccccc;
    --vscode-menu-background: #252526;
    --vscode-menu-selectionForeground: #ffffff;
    --vscode-menu-selectionBackground: #04395e;
    --vscode-menu-separatorBackground: #454545;
    --vscode-toolbar-hoverBackground: rgba(90, 93, 94, 0.31);
    --vscode-toolbar-activeBackground: rgba(99, 102, 103, 0.31);
    --vscode-editor-snippetTabstopHighlightBackground: rgba(124, 124, 124, 0.3);
    --vscode-editor-snippetFinalTabstopHighlightBorder: #525252;
    --vscode-breadcrumb-foreground: rgba(204, 204, 204, 0.8);
    --vscode-breadcrumb-background: #1e1e1e;
    --vscode-breadcrumb-focusForeground: #e0e0e0;
    --vscode-breadcrumb-activeSelectionForeground: #e0e0e0;
    --vscode-breadcrumbPicker-background: #252526;
    --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
    --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
    --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
    --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
    --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
    --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
    --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
    --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
    --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
    --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
    --vscode-minimap-findMatchHighlight: #d18616;
    --vscode-minimap-selectionOccurrenceHighlight: #676767;
    --vscode-minimap-selectionHighlight: #264f78;
    --vscode-minimap-infoHighlight: #3794ff;
    --vscode-minimap-warningHighlight: #cca700;
    --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
    --vscode-minimap-foregroundOpacity: #000000;
    --vscode-minimapSlider-background: rgba(121, 121, 121, 0.2);
    --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
    --vscode-minimapSlider-activeBackground: rgba(191, 191, 191, 0.2);
    --vscode-problemsErrorIcon-foreground: #f14c4c;
    --vscode-problemsWarningIcon-foreground: #cca700;
    --vscode-problemsInfoIcon-foreground: #3794ff;
    --vscode-charts-foreground: #cccccc;
    --vscode-charts-lines: rgba(204, 204, 204, 0.5);
    --vscode-charts-red: #f14c4c;
    --vscode-charts-blue: #3794ff;
    --vscode-charts-yellow: #cca700;
    --vscode-charts-orange: #d18616;
    --vscode-charts-green: #89d185;
    --vscode-charts-purple: #b180d7;
    --vscode-diffEditor-move\.border: rgba(139, 139, 139, 0.61);
    --vscode-diffEditor-moveActive\.border: #ffa500;
    --vscode-symbolIcon-arrayForeground: #cccccc;
    --vscode-symbolIcon-booleanForeground: #cccccc;
    --vscode-symbolIcon-classForeground: #ee9d28;
    --vscode-symbolIcon-colorForeground: #cccccc;
    --vscode-symbolIcon-constantForeground: #cccccc;
    --vscode-symbolIcon-constructorForeground: #b180d7;
    --vscode-symbolIcon-enumeratorForeground: #ee9d28;
    --vscode-symbolIcon-enumeratorMemberForeground: #75beff;
    --vscode-symbolIcon-eventForeground: #ee9d28;
    --vscode-symbolIcon-fieldForeground: #75beff;
    --vscode-symbolIcon-fileForeground: #cccccc;
    --vscode-symbolIcon-folderForeground: #cccccc;
    --vscode-symbolIcon-functionForeground: #b180d7;
    --vscode-symbolIcon-interfaceForeground: #75beff;
    --vscode-symbolIcon-keyForeground: #cccccc;
    --vscode-symbolIcon-keywordForeground: #cccccc;
    --vscode-symbolIcon-methodForeground: #b180d7;
    --vscode-symbolIcon-moduleForeground: #cccccc;
    --vscode-symbolIcon-namespaceForeground: #cccccc;
    --vscode-symbolIcon-nullForeground: #cccccc;
    --vscode-symbolIcon-numberForeground: #cccccc;
    --vscode-symbolIcon-objectForeground: #cccccc;
    --vscode-symbolIcon-operatorForeground: #cccccc;
    --vscode-symbolIcon-packageForeground: #cccccc;
    --vscode-symbolIcon-propertyForeground: #cccccc;
    --vscode-symbolIcon-referenceForeground: #cccccc;
    --vscode-symbolIcon-snippetForeground: #cccccc;
    --vscode-symbolIcon-stringForeground: #cccccc;
    --vscode-symbolIcon-structForeground: #cccccc;
    --vscode-symbolIcon-textForeground: #cccccc;
    --vscode-symbolIcon-typeParameterForeground: #cccccc;
    --vscode-symbolIcon-unitForeground: #cccccc;
    --vscode-symbolIcon-variableForeground: #75beff;
    --vscode-actionBar-toggledBackground: #383a49;
    --vscode-editorHoverWidget-highlightForeground: #2aaaff;
    --vscode-editor-lineHighlightBorder: #282828;
    --vscode-editor-rangeHighlightBackground: rgba(255, 255, 255, 0.04);
    --vscode-editor-symbolHighlightBackground: rgba(234, 92, 0, 0.33);
    --vscode-editorCursor-foreground: #aeafad;
    --vscode-editorWhitespace-foreground: rgba(227, 228, 226, 0.16);
    --vscode-editorLineNumber-foreground: #858585;
    --vscode-editorIndentGuide-background: #404040;
    --vscode-editorIndentGuide-activeBackground: #707070;
    --vscode-editorIndentGuide-background1: #404040;
    --vscode-editorIndentGuide-background2: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background3: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background4: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background5: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background6: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground1: #707070;
    --vscode-editorIndentGuide-activeBackground2: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground3: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground4: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground5: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground6: rgba(0, 0, 0, 0);
    --vscode-editorActiveLineNumber-foreground: #c6c6c6;
    --vscode-editorLineNumber-activeForeground: #c6c6c6;
    --vscode-editorRuler-foreground: #5a5a5a;
    --vscode-editorCodeLens-foreground: #999999;
    --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
    --vscode-editorBracketMatch-border: #888888;
    --vscode-editorOverviewRuler-border: rgba(127, 127, 127, 0.3);
    --vscode-editorGutter-background: #1e1e1e;
    --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.67);
    --vscode-editorGhostText-foreground: rgba(255, 255, 255, 0.34);
    --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
    --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
    --vscode-editorOverviewRuler-warningForeground: #cca700;
    --vscode-editorOverviewRuler-infoForeground: #3794ff;
    --vscode-editorBracketHighlight-foreground1: #ffd700;
    --vscode-editorBracketHighlight-foreground2: #da70d6;
    --vscode-editorBracketHighlight-foreground3: #179fff;
    --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-unexpectedBracket\.foreground: rgba(255, 18, 18, 0.8);
    --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
    --vscode-editorUnicodeHighlight-border: #bd9b03;
    --vscode-editorUnicodeHighlight-background: rgba(189, 155, 3, 0.15);
    --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
    --vscode-editor-foldBackground: rgba(38, 79, 120, 0.3);
    --vscode-editorGutter-foldingControlForeground: #c5c5c5;
    --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
    --vscode-editor-wordHighlightBackground: rgba(87, 87, 87, 0.72);
    --vscode-editor-wordHighlightStrongBackground: rgba(0, 73, 114, 0.72);
    --vscode-editor-wordHighlightTextBackground: rgba(87, 87, 87, 0.72);
    --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
    --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
    --vscode-editorOverviewRuler-wordHighlightTextForeground: rgba(160, 160, 160, 0.8);
    --vscode-peekViewTitle-background: #252526;
    --vscode-peekViewTitleLabel-foreground: #ffffff;
    --vscode-peekViewTitleDescription-foreground: rgba(204, 204, 204, 0.7);
    --vscode-peekView-border: #3794ff;
    --vscode-peekViewResult-background: #252526;
    --vscode-peekViewResult-lineForeground: #bbbbbb;
    --vscode-peekViewResult-fileForeground: #ffffff;
    --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
    --vscode-peekViewResult-selectionForeground: #ffffff;
    --vscode-peekViewEditor-background: #001f33;
    --vscode-peekViewEditorGutter-background: #001f33;
    --vscode-peekViewEditorStickyScroll-background: #001f33;
    --vscode-peekViewResult-matchHighlightBackground: rgba(234, 92, 0, 0.3);
    --vscode-peekViewEditor-matchHighlightBackground: rgba(255, 143, 0, 0.6);
    --vscode-editorMarkerNavigationError-background: #f14c4c;
    --vscode-editorMarkerNavigationError-headerBackground: rgba(241, 76, 76, 0.1);
    --vscode-editorMarkerNavigationWarning-background: #cca700;
    --vscode-editorMarkerNavigationWarning-headerBackground: rgba(204, 167, 0, 0.1);
    --vscode-editorMarkerNavigationInfo-background: #3794ff;
    --vscode-editorMarkerNavigationInfo-headerBackground: rgba(55, 148, 255, 0.1);
    --vscode-editorMarkerNavigation-background: #1e1e1e;
    --vscode-editorSuggestWidget-background: #252526;
    --vscode-editorSuggestWidget-border: #454545;
    --vscode-editorSuggestWidget-foreground: #d4d4d4;
    --vscode-editorSuggestWidget-selectedForeground: #ffffff;
    --vscode-editorSuggestWidget-selectedIconForeground: #ffffff;
    --vscode-editorSuggestWidget-selectedBackground: #04395e;
    --vscode-editorSuggestWidget-highlightForeground: #2aaaff;
    --vscode-editorSuggestWidget-focusHighlightForeground: #2aaaff;
    --vscode-editorSuggestWidgetStatus-foreground: rgba(212, 212, 212, 0.5);
    --vscode-tab-activeBackground: #1e1e1e;
    --vscode-tab-unfocusedActiveBackground: #1e1e1e;
    --vscode-tab-inactiveBackground: #2d2d2d;
    --vscode-tab-unfocusedInactiveBackground: #2d2d2d;
    --vscode-tab-activeForeground: #ffffff;
    --vscode-tab-inactiveForeground: rgba(255, 255, 255, 0.5);
    --vscode-tab-unfocusedActiveForeground: rgba(255, 255, 255, 0.5);
    --vscode-tab-unfocusedInactiveForeground: rgba(255, 255, 255, 0.25);
    --vscode-tab-border: #252526;
    --vscode-tab-lastPinnedBorder: rgba(204, 204, 204, 0.2);
    --vscode-tab-activeModifiedBorder: #3399cc;
    --vscode-tab-inactiveModifiedBorder: rgba(51, 153, 204, 0.5);
    --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 153, 204, 0.5);
    --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 153, 204, 0.25);
    --vscode-editorPane-background: #1e1e1e;
    --vscode-editorGroupHeader-tabsBackground: #252526;
    --vscode-editorGroupHeader-noTabsBackground: #1e1e1e;
    --vscode-editorGroup-border: #444444;
    --vscode-editorGroup-dropBackground: rgba(83, 89, 93, 0.5);
    --vscode-editorGroup-dropIntoPromptForeground: #cccccc;
    --vscode-editorGroup-dropIntoPromptBackground: #252526;
    --vscode-sideBySideEditor-horizontalBorder: #444444;
    --vscode-sideBySideEditor-verticalBorder: #444444;
    --vscode-panel-background: #1e1e1e;
    --vscode-panel-border: rgba(128, 128, 128, 0.35);
    --vscode-panelTitle-activeForeground: #e7e7e7;
    --vscode-panelTitle-inactiveForeground: rgba(231, 231, 231, 0.6);
    --vscode-panelTitle-activeBorder: #e7e7e7;
    --vscode-panel-dropBorder: #e7e7e7;
    --vscode-panelSection-dropBackground: rgba(83, 89, 93, 0.5);
    --vscode-panelSectionHeader-background: rgba(128, 128, 128, 0.2);
    --vscode-panelSection-border: rgba(128, 128, 128, 0.35);
    --vscode-banner-background: #04395e;
    --vscode-banner-foreground: #ffffff;
    --vscode-banner-iconForeground: #3794ff;
    --vscode-statusBar-foreground: #ffffff;
    --vscode-statusBar-noFolderForeground: #ffffff;
    --vscode-statusBar-background: #007acc;
    --vscode-statusBar-noFolderBackground: #68217a;
    --vscode-statusBar-focusBorder: #ffffff;
    --vscode-statusBarItem-activeBackground: rgba(255, 255, 255, 0.18);
    --vscode-statusBarItem-focusBorder: #ffffff;
    --vscode-statusBarItem-hoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-hoverForeground: #ffffff;
    --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
    --vscode-statusBarItem-prominentForeground: #ffffff;
    --vscode-statusBarItem-prominentBackground: rgba(0, 0, 0, 0.5);
    --vscode-statusBarItem-prominentHoverForeground: #ffffff;
    --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
    --vscode-statusBarItem-errorBackground: #c72e0f;
    --vscode-statusBarItem-errorForeground: #ffffff;
    --vscode-statusBarItem-errorHoverForeground: #ffffff;
    --vscode-statusBarItem-errorHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-warningBackground: #7a6400;
    --vscode-statusBarItem-warningForeground: #ffffff;
    --vscode-statusBarItem-warningHoverForeground: #ffffff;
    --vscode-statusBarItem-warningHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-activityBar-background: #333333;
    --vscode-activityBar-foreground: #ffffff;
    --vscode-activityBar-inactiveForeground: rgba(255, 255, 255, 0.4);
    --vscode-activityBar-activeBorder: #ffffff;
    --vscode-activityBar-dropBorder: #ffffff;
    --vscode-activityBarBadge-background: #007acc;
    --vscode-activityBarBadge-foreground: #ffffff;
    --vscode-profileBadge-background: #4d4d4d;
    --vscode-profileBadge-foreground: #ffffff;
    --vscode-statusBarItem-remoteBackground: #16825d;
    --vscode-statusBarItem-remoteForeground: #ffffff;
    --vscode-statusBarItem-remoteHoverForeground: #ffffff;
    --vscode-statusBarItem-remoteHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-offlineBackground: #6c1717;
    --vscode-statusBarItem-offlineForeground: #ffffff;
    --vscode-statusBarItem-offlineHoverForeground: #ffffff;
    --vscode-statusBarItem-offlineHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-extensionBadge-remoteBackground: #007acc;
    --vscode-extensionBadge-remoteForeground: #ffffff;
    --vscode-sideBar-background: #252526;
    --vscode-sideBarTitle-foreground: #bbbbbb;
    --vscode-sideBar-dropBackground: rgba(83, 89, 93, 0.5);
    --vscode-sideBarSectionHeader-background: rgba(0, 0, 0, 0);
    --vscode-sideBarSectionHeader-border: rgba(204, 204, 204, 0.2);
    --vscode-titleBar-activeForeground: #cccccc;
    --vscode-titleBar-inactiveForeground: rgba(204, 204, 204, 0.6);
    --vscode-titleBar-activeBackground: #2863ed;
    --vscode-titleBar-inactiveBackground: #0d2868;
    --vscode-menubar-selectionForeground: #cccccc;
    --vscode-menubar-selectionBackground: rgba(90, 93, 94, 0.31);
    --vscode-commandCenter-foreground: #cccccc;
    --vscode-commandCenter-activeForeground: #cccccc;
    --vscode-commandCenter-inactiveForeground: rgba(204, 204, 204, 0.6);
    --vscode-commandCenter-background: rgba(255, 255, 255, 0.05);
    --vscode-commandCenter-activeBackground: rgba(255, 255, 255, 0.08);
    --vscode-commandCenter-border: rgba(204, 204, 204, 0.2);
    --vscode-commandCenter-activeBorder: rgba(204, 204, 204, 0.3);
    --vscode-commandCenter-inactiveBorder: rgba(204, 204, 204, 0.15);
    --vscode-notificationCenter-border: #303031;
    --vscode-notificationToast-border: #303031;
    --vscode-notifications-foreground: #cccccc;
    --vscode-notifications-background: #252526;
    --vscode-notificationLink-foreground: #3794ff;
    --vscode-notificationCenterHeader-background: #303031;
    --vscode-notifications-border: #303031;
    --vscode-notificationsErrorIcon-foreground: #f14c4c;
    --vscode-notificationsWarningIcon-foreground: #cca700;
    --vscode-notificationsInfoIcon-foreground: #3794ff;
    --vscode-chat-requestBorder: rgba(255, 255, 255, 0.1);
    --vscode-chat-slashCommandBackground: #4d4d4d;
    --vscode-chat-slashCommandForeground: #ffffff;
    --vscode-chat-avatarBackground: #1f1f1f;
    --vscode-chat-avatarForeground: #cccccc;
    --vscode-simpleFindWidget-sashBorder: #454545;
    --vscode-commentsView-resolvedIcon: rgba(204, 204, 204, 0.5);
    --vscode-commentsView-unresolvedIcon: #007fd4;
    --vscode-editorCommentsWidget-resolvedBorder: rgba(204, 204, 204, 0.5);
    --vscode-editorCommentsWidget-unresolvedBorder: #007fd4;
    --vscode-editorCommentsWidget-rangeBackground: rgba(0, 127, 212, 0.1);
    --vscode-editorCommentsWidget-rangeActiveBackground: rgba(0, 127, 212, 0.1);
    --vscode-editorGutter-commentRangeForeground: #37373d;
    --vscode-editorOverviewRuler-commentForeground: #37373d;
    --vscode-editorOverviewRuler-commentUnresolvedForeground: #37373d;
    --vscode-editorGutter-commentGlyphForeground: #d4d4d4;
    --vscode-editorGutter-commentUnresolvedGlyphForeground: #d4d4d4;
    --vscode-debugToolBar-background: #333333;
    --vscode-debugIcon-startForeground: #89d185;
    --vscode-editor-stackFrameHighlightBackground: rgba(255, 255, 0, 0.2);
    --vscode-editor-focusedStackFrameHighlightBackground: rgba(122, 189, 122, 0.3);
    --vscode-mergeEditor-change\.background: rgba(155, 185, 85, 0.2);
    --vscode-mergeEditor-change\.word\.background: rgba(156, 204, 44, 0.2);
    --vscode-mergeEditor-changeBase\.background: #4b1818;
    --vscode-mergeEditor-changeBase\.word\.background: #6f1313;
    --vscode-mergeEditor-conflict\.unhandledUnfocused\.border: rgba(255, 166, 0, 0.48);
    --vscode-mergeEditor-conflict\.unhandledFocused\.border: #ffa600;
    --vscode-mergeEditor-conflict\.handledUnfocused\.border: rgba(134, 134, 134, 0.29);
    --vscode-mergeEditor-conflict\.handledFocused\.border: rgba(193, 193, 193, 0.8);
    --vscode-mergeEditor-conflict\.handled\.minimapOverViewRuler: rgba(173, 172, 168, 0.93);
    --vscode-mergeEditor-conflict\.unhandled\.minimapOverViewRuler: #fcba03;
    --vscode-mergeEditor-conflictingLines\.background: rgba(255, 234, 0, 0.28);
    --vscode-mergeEditor-conflict\.input1\.background: rgba(64, 200, 174, 0.2);
    --vscode-mergeEditor-conflict\.input2\.background: rgba(64, 166, 255, 0.2);
    --vscode-settings-headerForeground: #e7e7e7;
    --vscode-settings-settingsHeaderHoverForeground: rgba(231, 231, 231, 0.7);
    --vscode-settings-modifiedItemIndicator: #0c7d9d;
    --vscode-settings-headerBorder: rgba(128, 128, 128, 0.35);
    --vscode-settings-sashBorder: rgba(128, 128, 128, 0.35);
    --vscode-settings-dropdownBackground: #3c3c3c;
    --vscode-settings-dropdownForeground: #f0f0f0;
    --vscode-settings-dropdownBorder: #3c3c3c;
    --vscode-settings-dropdownListBorder: #454545;
    --vscode-settings-checkboxBackground: #3c3c3c;
    --vscode-settings-checkboxForeground: #f0f0f0;
    --vscode-settings-checkboxBorder: #6b6b6b;
    --vscode-settings-textInputBackground: #3c3c3c;
    --vscode-settings-textInputForeground: #cccccc;
    --vscode-settings-numberInputBackground: #3c3c3c;
    --vscode-settings-numberInputForeground: #cccccc;
    --vscode-settings-focusedRowBackground: rgba(42, 45, 46, 0.6);
    --vscode-settings-rowHoverBackground: rgba(42, 45, 46, 0.3);
    --vscode-settings-focusedRowBorder: #007fd4;
    --vscode-terminal-foreground: #cccccc;
    --vscode-terminal-selectionBackground: #264f78;
    --vscode-terminal-inactiveSelectionBackground: #3a3d41;
    --vscode-terminalCommandDecoration-defaultBackground: rgba(255, 255, 255, 0.25);
    --vscode-terminalCommandDecoration-successBackground: #1b81a8;
    --vscode-terminalCommandDecoration-errorBackground: #f14c4c;
    --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
    --vscode-terminal-border: rgba(128, 128, 128, 0.35);
    --vscode-terminal-findMatchBackground: #515c6a;
    --vscode-terminal-hoverHighlightBackground: rgba(38, 79, 120, 0.13);
    --vscode-terminal-findMatchHighlightBackground: rgba(234, 92, 0, 0.33);
    --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    --vscode-terminal-dropBackground: rgba(83, 89, 93, 0.5);
    --vscode-testing-iconFailed: #f14c4c;
    --vscode-testing-iconErrored: #f14c4c;
    --vscode-testing-iconPassed: #73c991;
    --vscode-testing-runAction: #73c991;
    --vscode-testing-iconQueued: #cca700;
    --vscode-testing-iconUnset: #848484;
    --vscode-testing-iconSkipped: #848484;
    --vscode-testing-peekBorder: #f14c4c;
    --vscode-testing-peekHeaderBackground: rgba(241, 76, 76, 0.1);
    --vscode-testing-message\.error\.decorationForeground: #f14c4c;
    --vscode-testing-message\.error\.lineBackground: rgba(255, 0, 0, 0.2);
    --vscode-testing-message\.info\.decorationForeground: rgba(212, 212, 212, 0.5);
    --vscode-welcomePage-tileBackground: #252526;
    --vscode-welcomePage-tileHoverBackground: #2c2c2d;
    --vscode-welcomePage-tileBorder: rgba(255, 255, 255, 0.1);
    --vscode-welcomePage-progress\.background: #3c3c3c;
    --vscode-welcomePage-progress\.foreground: #3794ff;
    --vscode-walkthrough-stepTitle\.foreground: #ffffff;
    --vscode-walkThrough-embeddedEditorBackground: rgba(0, 0, 0, 0.4);
    --vscode-inlineChat-background: #252526;
    --vscode-inlineChat-border: #454545;
    --vscode-inlineChat-shadow: rgba(0, 0, 0, 0.36);
    --vscode-inlineChat-regionHighlight: rgba(38, 79, 120, 0.25);
    --vscode-inlineChatInput-border: #454545;
    --vscode-inlineChatInput-focusBorder: #007fd4;
    --vscode-inlineChatInput-placeholderForeground: #a6a6a6;
    --vscode-inlineChatInput-background: #3c3c3c;
    --vscode-inlineChatDiff-inserted: rgba(156, 204, 44, 0.1);
    --vscode-inlineChatDiff-removed: rgba(255, 0, 0, 0.1);
    --vscode-debugExceptionWidget-border: #a31515;
    --vscode-debugExceptionWidget-background: #420b0d;
    --vscode-ports-iconRunningProcessForeground: #369432;
    --vscode-statusBar-debuggingBackground: #cc6633;
    --vscode-statusBar-debuggingForeground: #ffffff;
    --vscode-commandCenter-debuggingBackground: rgba(204, 102, 51, 0.26);
    --vscode-editor-inlineValuesForeground: rgba(255, 255, 255, 0.5);
    --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
    --vscode-editorGutter-modifiedBackground: #1b81a8;
    --vscode-editorGutter-addedBackground: #487e02;
    --vscode-editorGutter-deletedBackground: #f14c4c;
    --vscode-minimapGutter-modifiedBackground: #1b81a8;
    --vscode-minimapGutter-addedBackground: #487e02;
    --vscode-minimapGutter-deletedBackground: #f14c4c;
    --vscode-editorOverviewRuler-modifiedForeground: rgba(27, 129, 168, 0.6);
    --vscode-editorOverviewRuler-addedForeground: rgba(72, 126, 2, 0.6);
    --vscode-editorOverviewRuler-deletedForeground: rgba(241, 76, 76, 0.6);
    --vscode-debugIcon-breakpointForeground: #e51400;
    --vscode-debugIcon-breakpointDisabledForeground: #848484;
    --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
    --vscode-debugIcon-breakpointCurrentStackframeForeground: #ffcc00;
    --vscode-debugIcon-breakpointStackframeForeground: #89d185;
    --vscode-notebook-cellBorderColor: #37373d;
    --vscode-notebook-focusedEditorBorder: #007fd4;
    --vscode-notebookStatusSuccessIcon-foreground: #89d185;
    --vscode-notebookEditorOverviewRuler-runningCellForeground: #89d185;
    --vscode-notebookStatusErrorIcon-foreground: #f48771;
    --vscode-notebookStatusRunningIcon-foreground: #cccccc;
    --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
    --vscode-notebook-selectedCellBackground: #37373d;
    --vscode-notebook-selectedCellBorder: #37373d;
    --vscode-notebook-focusedCellBorder: #007fd4;
    --vscode-notebook-inactiveFocusedCellBorder: #37373d;
    --vscode-notebook-cellStatusBarItemHoverBackground: rgba(255, 255, 255, 0.15);
    --vscode-notebook-cellInsertionIndicator: #007fd4;
    --vscode-notebookScrollbarSlider-background: rgba(121, 121, 121, 0.4);
    --vscode-notebookScrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    --vscode-notebookScrollbarSlider-activeBackground: rgba(191, 191, 191, 0.4);
    --vscode-notebook-symbolHighlightBackground: rgba(255, 255, 255, 0.04);
    --vscode-notebook-cellEditorBackground: #252526;
    --vscode-notebook-editorBackground: #1e1e1e;
    --vscode-keybindingTable-headerBackground: rgba(204, 204, 204, 0.04);
    --vscode-keybindingTable-rowsBackground: rgba(204, 204, 204, 0.04);
    --vscode-debugTokenExpression-name: #c586c0;
    --vscode-debugTokenExpression-value: rgba(204, 204, 204, 0.6);
    --vscode-debugTokenExpression-string: #ce9178;
    --vscode-debugTokenExpression-boolean: #4e94ce;
    --vscode-debugTokenExpression-number: #b5cea8;
    --vscode-debugTokenExpression-error: #f48771;
    --vscode-debugView-exceptionLabelForeground: #cccccc;
    --vscode-debugView-exceptionLabelBackground: #6c2022;
    --vscode-debugView-stateLabelForeground: #cccccc;
    --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
    --vscode-debugView-valueChangedHighlight: #569cd6;
    --vscode-debugConsole-infoForeground: #3794ff;
    --vscode-debugConsole-warningForeground: #cca700;
    --vscode-debugConsole-errorForeground: #f48771;
    --vscode-debugConsole-sourceForeground: #cccccc;
    --vscode-debugConsoleInputIcon-foreground: #cccccc;
    --vscode-debugIcon-pauseForeground: #75beff;
    --vscode-debugIcon-stopForeground: #f48771;
    --vscode-debugIcon-disconnectForeground: #f48771;
    --vscode-debugIcon-restartForeground: #89d185;
    --vscode-debugIcon-stepOverForeground: #75beff;
    --vscode-debugIcon-stepIntoForeground: #75beff;
    --vscode-debugIcon-stepOutForeground: #75beff;
    --vscode-debugIcon-continueForeground: #75beff;
    --vscode-debugIcon-stepBackForeground: #75beff;
    --vscode-extensionButton-background: #0e639c;
    --vscode-extensionButton-foreground: #ffffff;
    --vscode-extensionButton-hoverBackground: #1177bb;
    --vscode-extensionButton-separator: rgba(255, 255, 255, 0.4);
    --vscode-extensionButton-prominentBackground: #0e639c;
    --vscode-extensionButton-prominentForeground: #ffffff;
    --vscode-extensionButton-prominentHoverBackground: #1177bb;
    --vscode-extensionIcon-starForeground: #ff8e00;
    --vscode-extensionIcon-verifiedForeground: #3794ff;
    --vscode-extensionIcon-preReleaseForeground: #1d9271;
    --vscode-extensionIcon-sponsorForeground: #d758b3;
    --vscode-terminal-ansiBlack: #000000;
    --vscode-terminal-ansiRed: #cd3131;
    --vscode-terminal-ansiGreen: #0dbc79;
    --vscode-terminal-ansiYellow: #e5e510;
    --vscode-terminal-ansiBlue: #2472c8;
    --vscode-terminal-ansiMagenta: #bc3fbc;
    --vscode-terminal-ansiCyan: #11a8cd;
    --vscode-terminal-ansiWhite: #e5e5e5;
    --vscode-terminal-ansiBrightBlack: #666666;
    --vscode-terminal-ansiBrightRed: #f14c4c;
    --vscode-terminal-ansiBrightGreen: #23d18b;
    --vscode-terminal-ansiBrightYellow: #f5f543;
    --vscode-terminal-ansiBrightBlue: #3b8eea;
    --vscode-terminal-ansiBrightMagenta: #d670d6;
    --vscode-terminal-ansiBrightCyan: #29b8db;
    --vscode-terminal-ansiBrightWhite: #e5e5e5;
    --vscode-interactive-activeCodeBorder: #3794ff;
    --vscode-interactive-inactiveCodeBorder: #37373d;
    --vscode-gitDecoration-addedResourceForeground: #81b88b;
    --vscode-gitDecoration-modifiedResourceForeground: #e2c08d;
    --vscode-gitDecoration-deletedResourceForeground: #c74e39;
    --vscode-gitDecoration-renamedResourceForeground: #73c991;
    --vscode-gitDecoration-untrackedResourceForeground: #73c991;
    --vscode-gitDecoration-ignoredResourceForeground: #8c8c8c;
    --vscode-gitDecoration-stageModifiedResourceForeground: #e2c08d;
    --vscode-gitDecoration-stageDeletedResourceForeground: #c74e39;
    --vscode-gitDecoration-conflictingResourceForeground: #e4676b;
    --vscode-gitDecoration-submoduleResourceForeground: #8db9e2;
    --vscode-bookmarks-lineBackground: rgba(0, 0, 0, 0);
    --vscode-bookmarks-lineBorder: rgba(0, 0, 0, 0);
    --vscode-bookmarks-overviewRuler: rgba(21, 126, 251, 0.53);
    --vscode-gitlens-gutterBackgroundColor: rgba(255, 255, 255, 0.07);
    --vscode-gitlens-gutterForegroundColor: #bebebe;
    --vscode-gitlens-gutterUncommittedForegroundColor: rgba(0, 188, 242, 0.6);
    --vscode-gitlens-trailingLineBackgroundColor: rgba(0, 0, 0, 0);
    --vscode-gitlens-trailingLineForegroundColor: rgba(153, 153, 153, 0.35);
    --vscode-gitlens-lineHighlightBackgroundColor: rgba(0, 188, 242, 0.2);
    --vscode-gitlens-lineHighlightOverviewRulerColor: rgba(0, 188, 242, 0.6);
    --vscode-gitlens-openAutolinkedIssueIconColor: #3fb950;
    --vscode-gitlens-closedAutolinkedIssueIconColor: #a371f7;
    --vscode-gitlens-closedPullRequestIconColor: #f85149;
    --vscode-gitlens-openPullRequestIconColor: #3fb950;
    --vscode-gitlens-mergedPullRequestIconColor: #a371f7;
    --vscode-gitlens-unpublishedChangesIconColor: #35b15e;
    --vscode-gitlens-unpublishedCommitIconColor: #35b15e;
    --vscode-gitlens-unpulledChangesIconColor: #b15e35;
    --vscode-gitlens-decorations\.addedForegroundColor: #81b88b;
    --vscode-gitlens-decorations\.copiedForegroundColor: #73c991;
    --vscode-gitlens-decorations\.deletedForegroundColor: #c74e39;
    --vscode-gitlens-decorations\.ignoredForegroundColor: #8c8c8c;
    --vscode-gitlens-decorations\.modifiedForegroundColor: #e2c08d;
    --vscode-gitlens-decorations\.untrackedForegroundColor: #73c991;
    --vscode-gitlens-decorations\.renamedForegroundColor: #73c991;
    --vscode-gitlens-decorations\.branchAheadForegroundColor: #35b15e;
    --vscode-gitlens-decorations\.branchBehindForegroundColor: #b15e35;
    --vscode-gitlens-decorations\.branchDivergedForegroundColor: #d8af1b;
    --vscode-gitlens-decorations\.branchUnpublishedForegroundColor: #35b15e;
    --vscode-gitlens-decorations\.branchMissingUpstreamForegroundColor: #c74e39;
    --vscode-gitlens-decorations\.statusMergingOrRebasingConflictForegroundColor: #c74e39;
    --vscode-gitlens-decorations\.statusMergingOrRebasingForegroundColor: #d8af1b;
    --vscode-gitlens-decorations\.workspaceRepoMissingForegroundColor: #909090;
    --vscode-gitlens-decorations\.workspaceCurrentForegroundColor: #35b15e;
    --vscode-gitlens-decorations\.workspaceRepoOpenForegroundColor: #35b15e;
    --vscode-gitlens-decorations\.worktreeHasUncommittedChangesForegroundColor: #e2c08d;
    --vscode-gitlens-decorations\.worktreeMissingForegroundColor: #c74e39;
    --vscode-gitlens-graphLane1Color: #15a0bf;
    --vscode-gitlens-graphLane2Color: #0669f7;
    --vscode-gitlens-graphLane3Color: #8e00c2;
    --vscode-gitlens-graphLane4Color: #c517b6;
    --vscode-gitlens-graphLane5Color: #d90171;
    --vscode-gitlens-graphLane6Color: #cd0101;
    --vscode-gitlens-graphLane7Color: #f25d2e;
    --vscode-gitlens-graphLane8Color: #f2ca33;
    --vscode-gitlens-graphLane9Color: #7bd938;
    --vscode-gitlens-graphLane10Color: #2ece9d;
    --vscode-gitlens-graphChangesColumnAddedColor: #347d39;
    --vscode-gitlens-graphChangesColumnDeletedColor: #c93c37;
    --vscode-gitlens-graphMinimapMarkerHeadColor: #05e617;
    --vscode-gitlens-graphScrollMarkerHeadColor: #05e617;
    --vscode-gitlens-graphMinimapMarkerUpstreamColor: #09ae17;
    --vscode-gitlens-graphScrollMarkerUpstreamColor: #09ae17;
    --vscode-gitlens-graphMinimapMarkerHighlightsColor: #fbff0a;
    --vscode-gitlens-graphScrollMarkerHighlightsColor: #fbff0a;
    --vscode-gitlens-graphMinimapMarkerLocalBranchesColor: #3087cf;
    --vscode-gitlens-graphScrollMarkerLocalBranchesColor: #3087cf;
    --vscode-gitlens-graphMinimapMarkerRemoteBranchesColor: #2b5e88;
    --vscode-gitlens-graphScrollMarkerRemoteBranchesColor: #2b5e88;
    --vscode-gitlens-graphMinimapMarkerStashesColor: #b34db3;
    --vscode-gitlens-graphScrollMarkerStashesColor: #b34db3;
    --vscode-gitlens-graphMinimapMarkerTagsColor: #6b562e;
    --vscode-gitlens-graphScrollMarkerTagsColor: #6b562e;
    --vscode-issues-newIssueDecoration: rgba(255, 255, 255, 0.28);
    --vscode-issues-open: #3fb950;
    --vscode-issues-closed: #cb2431;
    --vscode-pullRequests-merged: #8957e5;
    --vscode-pullRequests-draft: #6e7681;
    --vscode-pullRequests-open: #3fb950;
    --vscode-pullRequests-closed: #cb2431;
    --vscode-pullRequests-notification: #3794ff;
}