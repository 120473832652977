/* This file contains a list of variables available in a vscode webview and their values from the IntelliJ IDEA New UI Theme */
html {
    --vscode-font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    --vscode-font-weight: normal;
    --vscode-font-size: 16px;
    --vscode-editor-font-family: Menlo, Monaco, 'Courier New', monospace;
    --vscode-editor-font-weight: normal;
    --vscode-editor-font-size: 12px;
    --vscode-foreground: #616161;
    --vscode-disabledForeground: rgba(97, 97, 97, 0.5);
    --vscode-errorForeground: #a1260d;
    --vscode-descriptionForeground: #717171;
    --vscode-icon-foreground: #424242;
    --vscode-focusBorder: #aeb3c2;
    --vscode-textLink-foreground: #006ab1;
    --vscode-textLink-activeForeground: #006ab1;
    --vscode-textSeparator-foreground: rgba(0, 0, 0, 0.18);
    --vscode-textPreformat-foreground: #a31515;
    --vscode-textPreformat-background: rgba(0, 0, 0, 0.1);
    --vscode-textBlockQuote-background: #f2f2f2;
    --vscode-textBlockQuote-border: rgba(0, 122, 204, 0.5);
    --vscode-textCodeBlock-background: rgba(220, 220, 220, 0.4);
    --vscode-sash-hoverBorder: #aeb3c2;
    --vscode-badge-background: #c4c4c4;
    --vscode-badge-foreground: #333333;
    --vscode-scrollbar-shadow: #dddddd;
    --vscode-scrollbarSlider-background: rgba(58, 58, 58, 0.2);
    --vscode-scrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    --vscode-scrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
    --vscode-progressBar-background: #0e70c0;
    --vscode-editor-background: #ffffff;
    --vscode-editor-foreground: #000000;
    --vscode-editorStickyScroll-background: #ffffff;
    --vscode-editorStickyScrollHover-background: #f0f0f0;
    --vscode-editorStickyScroll-shadow: #dddddd;
    --vscode-editorWidget-background: #f7f8fa;
    --vscode-editorWidget-foreground: #616161;
    --vscode-editorWidget-border: #ebecf0;
    --vscode-editorError-foreground: #e51400;
    --vscode-editorWarning-foreground: #bf8803;
    --vscode-editorInfo-foreground: #1a85ff;
    --vscode-editorHint-foreground: #6c6c6c;
    --vscode-editorLink-activeForeground: #0000ff;
    --vscode-editor-selectionBackground: #a6d2ff;
    --vscode-editor-inactiveSelectionBackground: rgba(166, 210, 255, 0.5);
    --vscode-editor-selectionHighlightBackground: rgba(221, 219, 252, 0.5);
    --vscode-editor-findMatchBackground: #a6d2ff;
    --vscode-editor-findMatchHighlightBackground: rgba(252, 189, 56, 0.4);
    --vscode-editor-findRangeHighlightBackground: rgba(180, 180, 180, 0.3);
    --vscode-editor-findMatchBorder: rgba(0, 0, 0, 0.27);
    --vscode-editor-hoverHighlightBackground: rgba(173, 214, 255, 0.15);
    --vscode-editorHoverWidget-background: #f7f8fa;
    --vscode-editorHoverWidget-foreground: #616161;
    --vscode-editorHoverWidget-border: #ebecf0;
    --vscode-editorHoverWidget-statusBarBackground: #e8ebf0;
    --vscode-editorInlayHint-foreground: #999999;
    --vscode-editorInlayHint-background: #fafafa;
    --vscode-editorInlayHint-typeForeground: #999999;
    --vscode-editorInlayHint-typeBackground: #fafafa;
    --vscode-editorInlayHint-parameterForeground: #999999;
    --vscode-editorInlayHint-parameterBackground: #fafafa;
    --vscode-editorLightBulb-foreground: #ddb100;
    --vscode-editorLightBulbAutoFix-foreground: #007acc;
    --vscode-editorLightBulbAi-foreground: #ddb100;
    --vscode-editor-snippetTabstopHighlightBackground: rgba(10, 50, 100, 0.2);
    --vscode-editor-snippetFinalTabstopHighlightBorder: rgba(10, 50, 100, 0.5);
    --vscode-diffEditor-insertedTextBackground: rgba(156, 204, 44, 0.25);
    --vscode-diffEditor-removedTextBackground: rgba(255, 0, 0, 0.2);
    --vscode-diffEditor-insertedLineBackground: rgba(155, 185, 85, 0.2);
    --vscode-diffEditor-removedLineBackground: rgba(255, 0, 0, 0.2);
    --vscode-diffEditor-diagonalFill: rgba(34, 34, 34, 0.2);
    --vscode-diffEditor-unchangedRegionBackground: #f7f8fa;
    --vscode-diffEditor-unchangedRegionForeground: #616161;
    --vscode-diffEditor-unchangedCodeBackground: rgba(184, 184, 184, 0.16);
    --vscode-widget-shadow: rgba(0, 0, 0, 0.16);
    --vscode-widget-border: #ebecf0;
    --vscode-toolbar-hoverBackground: rgba(184, 184, 184, 0.31);
    --vscode-toolbar-activeBackground: rgba(166, 166, 166, 0.31);
    --vscode-breadcrumb-foreground: rgba(97, 97, 97, 0.8);
    --vscode-breadcrumb-background: #f7f8fa;
    --vscode-breadcrumb-focusForeground: #4e4e4e;
    --vscode-breadcrumb-activeSelectionForeground: #4e4e4e;
    --vscode-breadcrumbPicker-background: #f7f8fa;
    --vscode-merge-currentHeaderBackground: rgba(64, 200, 174, 0.5);
    --vscode-merge-currentContentBackground: rgba(64, 200, 174, 0.2);
    --vscode-merge-incomingHeaderBackground: rgba(64, 166, 255, 0.5);
    --vscode-merge-incomingContentBackground: rgba(64, 166, 255, 0.2);
    --vscode-merge-commonHeaderBackground: rgba(96, 96, 96, 0.4);
    --vscode-merge-commonContentBackground: rgba(96, 96, 96, 0.16);
    --vscode-editorOverviewRuler-currentContentForeground: rgba(64, 200, 174, 0.5);
    --vscode-editorOverviewRuler-incomingContentForeground: rgba(64, 166, 255, 0.5);
    --vscode-editorOverviewRuler-commonContentForeground: rgba(96, 96, 96, 0.4);
    --vscode-editorOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    --vscode-editorOverviewRuler-selectionHighlightForeground: rgba(160, 160, 160, 0.8);
    --vscode-problemsErrorIcon-foreground: #e51400;
    --vscode-problemsWarningIcon-foreground: #bf8803;
    --vscode-problemsInfoIcon-foreground: #1a85ff;
    --vscode-input-background: #ffffff;
    --vscode-input-foreground: #616161;
    --vscode-inputOption-activeBorder: #007acc;
    --vscode-inputOption-hoverBackground: rgba(184, 184, 184, 0.31);
    --vscode-inputOption-activeBackground: rgba(174, 179, 194, 0.2);
    --vscode-inputOption-activeForeground: #000000;
    --vscode-input-placeholderForeground: rgba(97, 97, 97, 0.5);
    --vscode-inputValidation-infoBackground: #d6ecf2;
    --vscode-inputValidation-infoBorder: #007acc;
    --vscode-inputValidation-warningBackground: #f6f5d2;
    --vscode-inputValidation-warningBorder: #b89500;
    --vscode-inputValidation-errorBackground: #f2dede;
    --vscode-inputValidation-errorBorder: #be1100;
    --vscode-dropdown-background: #ffffff;
    --vscode-dropdown-foreground: #616161;
    --vscode-dropdown-border: #cecece;
    --vscode-button-foreground: #ffffff;
    --vscode-button-separator: rgba(255, 255, 255, 0.4);
    --vscode-button-background: #007acc;
    --vscode-button-hoverBackground: #0062a3;
    --vscode-button-secondaryForeground: #ffffff;
    --vscode-button-secondaryBackground: #5f6a79;
    --vscode-button-secondaryHoverBackground: #4c5561;
    --vscode-checkbox-background: #ffffff;
    --vscode-checkbox-selectBackground: #f7f8fa;
    --vscode-checkbox-foreground: #616161;
    --vscode-checkbox-border: #cecece;
    --vscode-checkbox-selectBorder: #424242;
    --vscode-keybindingLabel-background: rgba(221, 221, 221, 0.4);
    --vscode-keybindingLabel-foreground: #555555;
    --vscode-keybindingLabel-border: rgba(204, 204, 204, 0.4);
    --vscode-keybindingLabel-bottomBorder: rgba(187, 187, 187, 0.4);
    --vscode-list-focusBackground: #dfdfdf;
    --vscode-list-focusForeground: #000000;
    --vscode-list-focusOutline: #dfdfdf;
    --vscode-list-focusAndSelectionOutline: #cfdefc;
    --vscode-list-activeSelectionBackground: #cfdefc;
    --vscode-list-activeSelectionForeground: #000000;
    --vscode-list-inactiveSelectionBackground: #dfdfdf;
    --vscode-list-inactiveSelectionForeground: #000000;
    --vscode-list-hoverBackground: #dfdfdf;
    --vscode-list-hoverForeground: #000000;
    --vscode-list-dropBackground: #d6ebff;
    --vscode-list-dropBetweenBackground: #424242;
    --vscode-list-highlightForeground: #0066bf;
    --vscode-list-focusHighlightForeground: #0066bf;
    --vscode-list-invalidItemForeground: #b89500;
    --vscode-list-errorForeground: #b01011;
    --vscode-list-warningForeground: #855f00;
    --vscode-listFilterWidget-background: #f7f8fa;
    --vscode-listFilterWidget-outline: rgba(0, 0, 0, 0);
    --vscode-listFilterWidget-noMatchesOutline: #be1100;
    --vscode-listFilterWidget-shadow: rgba(0, 0, 0, 0.16);
    --vscode-list-filterMatchBackground: rgba(252, 189, 56, 0.4);
    --vscode-list-deemphasizedForeground: #8e8e90;
    --vscode-tree-indentGuidesStroke: #aeb3c2;
    --vscode-tree-inactiveIndentGuidesStroke: rgba(174, 179, 194, 0.4);
    --vscode-tree-tableColumnsBorder: rgba(97, 97, 97, 0.13);
    --vscode-tree-tableOddRowsBackground: rgba(97, 97, 97, 0.04);
    --vscode-menu-foreground: #616161;
    --vscode-menu-background: #ffffff;
    --vscode-menu-selectionForeground: #000000;
    --vscode-menu-selectionBackground: #cfdefc;
    --vscode-menu-separatorBackground: #d4d4d4;
    --vscode-minimap-findMatchHighlight: #d18616;
    --vscode-minimap-selectionOccurrenceHighlight: #c9c9c9;
    --vscode-minimap-selectionHighlight: #add6ff;
    --vscode-minimap-infoHighlight: #1a85ff;
    --vscode-minimap-warningHighlight: #bf8803;
    --vscode-minimap-errorHighlight: rgba(255, 18, 18, 0.7);
    --vscode-minimap-background: #f7f8fa;
    --vscode-minimap-foregroundOpacity: #000000;
    --vscode-minimapSlider-background: rgba(58, 58, 58, 0.2);
    --vscode-minimapSlider-hoverBackground: rgba(100, 100, 100, 0.35);
    --vscode-minimapSlider-activeBackground: rgba(0, 0, 0, 0.3);
    --vscode-charts-foreground: #616161;
    --vscode-charts-lines: rgba(97, 97, 97, 0.5);
    --vscode-charts-red: #e51400;
    --vscode-charts-blue: #1a85ff;
    --vscode-charts-yellow: #bf8803;
    --vscode-charts-orange: #d18616;
    --vscode-charts-green: #388a34;
    --vscode-charts-purple: #652d90;
    --vscode-quickInput-background: #f7f8fa;
    --vscode-quickInput-foreground: #616161;
    --vscode-quickInputTitle-background: rgba(0, 0, 0, 0.06);
    --vscode-pickerGroup-foreground: #0066bf;
    --vscode-pickerGroup-border: #cccedb;
    --vscode-quickInputList-focusForeground: #000000;
    --vscode-quickInputList-focusBackground: #cfdefc;
    --vscode-search-resultsInfoForeground: #616161;
    --vscode-searchEditor-findMatchBackground: rgba(252, 189, 56, 0.26);
    --vscode-multiDiffEditor-headerBackground: #f7f8fa;
    --vscode-multiDiffEditor-border: #cccccc;
    --vscode-symbolIcon-arrayForeground: #616161;
    --vscode-symbolIcon-booleanForeground: #616161;
    --vscode-symbolIcon-classForeground: #d67e00;
    --vscode-symbolIcon-colorForeground: #616161;
    --vscode-symbolIcon-constantForeground: #616161;
    --vscode-symbolIcon-constructorForeground: #652d90;
    --vscode-symbolIcon-enumeratorForeground: #d67e00;
    --vscode-symbolIcon-enumeratorMemberForeground: #007acc;
    --vscode-symbolIcon-eventForeground: #d67e00;
    --vscode-symbolIcon-fieldForeground: #007acc;
    --vscode-symbolIcon-fileForeground: #616161;
    --vscode-symbolIcon-folderForeground: #616161;
    --vscode-symbolIcon-functionForeground: #652d90;
    --vscode-symbolIcon-interfaceForeground: #007acc;
    --vscode-symbolIcon-keyForeground: #616161;
    --vscode-symbolIcon-keywordForeground: #616161;
    --vscode-symbolIcon-methodForeground: #652d90;
    --vscode-symbolIcon-moduleForeground: #616161;
    --vscode-symbolIcon-namespaceForeground: #616161;
    --vscode-symbolIcon-nullForeground: #616161;
    --vscode-symbolIcon-numberForeground: #616161;
    --vscode-symbolIcon-objectForeground: #616161;
    --vscode-symbolIcon-operatorForeground: #616161;
    --vscode-symbolIcon-packageForeground: #616161;
    --vscode-symbolIcon-propertyForeground: #616161;
    --vscode-symbolIcon-referenceForeground: #616161;
    --vscode-symbolIcon-snippetForeground: #616161;
    --vscode-symbolIcon-stringForeground: #616161;
    --vscode-symbolIcon-structForeground: #616161;
    --vscode-symbolIcon-textForeground: #616161;
    --vscode-symbolIcon-typeParameterForeground: #616161;
    --vscode-symbolIcon-unitForeground: #616161;
    --vscode-symbolIcon-variableForeground: #007acc;
    --vscode-actionBar-toggledBackground: rgba(174, 179, 194, 0.2);
    --vscode-editorHoverWidget-highlightForeground: #0066bf;
    --vscode-editor-lineHighlightBackground: #f5f8fe;
    --vscode-editor-lineHighlightBorder: #eeeeee;
    --vscode-editor-rangeHighlightBackground: rgba(253, 255, 0, 0.2);
    --vscode-editor-symbolHighlightBackground: rgba(252, 189, 56, 0.4);
    --vscode-editorCursor-foreground: #000000;
    --vscode-editorMultiCursor-primary-foreground: #000000;
    --vscode-editorMultiCursor-secondary-foreground: #000000;
    --vscode-editorWhitespace-foreground: rgba(51, 51, 51, 0.2);
    --vscode-editorLineNumber-foreground: #b9beca;
    --vscode-editorIndentGuide-background: #ebecf0;
    --vscode-editorIndentGuide-activeBackground: #aeb3c2;
    --vscode-editorIndentGuide-background1: #ebecf0;
    --vscode-editorIndentGuide-background2: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background3: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background4: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background5: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-background6: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground1: #aeb3c2;
    --vscode-editorIndentGuide-activeBackground2: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground3: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground4: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground5: rgba(0, 0, 0, 0);
    --vscode-editorIndentGuide-activeBackground6: rgba(0, 0, 0, 0);
    --vscode-editorActiveLineNumber-foreground: #0b216f;
    --vscode-editorLineNumber-activeForeground: #767a8a;
    --vscode-editorLineNumber-dimmedForeground: #767a8a;
    --vscode-editorRuler-foreground: #d3d3d3;
    --vscode-editorCodeLens-foreground: #919191;
    --vscode-editorBracketMatch-background: rgba(0, 100, 0, 0.1);
    --vscode-editorBracketMatch-border: #b9b9b9;
    --vscode-editorOverviewRuler-border: #f7f8fa;
    --vscode-editorOverviewRuler-background: #f7f8fa;
    --vscode-editorGutter-background: #ffffff;
    --vscode-editorUnnecessaryCode-opacity: rgba(0, 0, 0, 0.47);
    --vscode-editorGhostText-foreground: rgba(0, 0, 0, 0.47);
    --vscode-editorOverviewRuler-rangeHighlightForeground: rgba(0, 122, 204, 0.6);
    --vscode-editorOverviewRuler-errorForeground: rgba(255, 18, 18, 0.7);
    --vscode-editorOverviewRuler-warningForeground: #bf8803;
    --vscode-editorOverviewRuler-infoForeground: #1a85ff;
    --vscode-editorBracketHighlight-foreground1: #0431fa;
    --vscode-editorBracketHighlight-foreground2: #319331;
    --vscode-editorBracketHighlight-foreground3: #7b3814;
    --vscode-editorBracketHighlight-foreground4: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-foreground5: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-foreground6: rgba(0, 0, 0, 0);
    --vscode-editorBracketHighlight-unexpectedBracket-foreground: rgba(255, 18, 18, 0.8);
    --vscode-editorBracketPairGuide-background1: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background2: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background3: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background4: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background5: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-background6: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground1: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground2: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground3: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground4: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground5: rgba(0, 0, 0, 0);
    --vscode-editorBracketPairGuide-activeBackground6: rgba(0, 0, 0, 0);
    --vscode-editorUnicodeHighlight-border: #bf8803;
    --vscode-diffEditor-move-border: rgba(139, 139, 139, 0.61);
    --vscode-diffEditor-moveActive-border: #ffa500;
    --vscode-diffEditor-unchangedRegionShadow: rgba(115, 115, 115, 0.75);
    --vscode-editorOverviewRuler-bracketMatchForeground: #a0a0a0;
    --vscode-editor-foldBackground: rgba(166, 210, 255, 0.3);
    --vscode-editorGutter-foldingControlForeground: #424242;
    --vscode-editor-linkedEditingBackground: rgba(255, 0, 0, 0.3);
    --vscode-editor-wordHighlightBackground: rgba(221, 219, 252, 0.5);
    --vscode-editor-wordHighlightStrongBackground: rgba(252, 216, 232, 0.5);
    --vscode-editor-wordHighlightTextBackground: rgba(221, 219, 252, 0.5);
    --vscode-editorOverviewRuler-wordHighlightForeground: rgba(160, 160, 160, 0.8);
    --vscode-editorOverviewRuler-wordHighlightStrongForeground: rgba(192, 160, 192, 0.8);
    --vscode-editorOverviewRuler-wordHighlightTextForeground: rgba(160, 160, 160, 0.8);
    --vscode-peekViewTitle-background: #f3f3f3;
    --vscode-peekViewTitleLabel-foreground: #000000;
    --vscode-peekViewTitleDescription-foreground: #616161;
    --vscode-peekView-border: #1a85ff;
    --vscode-peekViewResult-background: #f3f3f3;
    --vscode-peekViewResult-lineForeground: #646465;
    --vscode-peekViewResult-fileForeground: #1e1e1e;
    --vscode-peekViewResult-selectionBackground: rgba(51, 153, 255, 0.2);
    --vscode-peekViewResult-selectionForeground: #6c6c6c;
    --vscode-peekViewEditor-background: #f2f8fc;
    --vscode-peekViewEditorGutter-background: #f2f8fc;
    --vscode-peekViewEditorStickyScroll-background: #f2f8fc;
    --vscode-peekViewResult-matchHighlightBackground: rgba(234, 92, 0, 0.3);
    --vscode-peekViewEditor-matchHighlightBackground: rgba(245, 216, 2, 0.87);
    --vscode-editorMarkerNavigationError-background: #e51400;
    --vscode-editorMarkerNavigationError-headerBackground: rgba(229, 20, 0, 0.1);
    --vscode-editorMarkerNavigationWarning-background: #bf8803;
    --vscode-editorMarkerNavigationWarning-headerBackground: rgba(191, 136, 3, 0.1);
    --vscode-editorMarkerNavigationInfo-background: #1a85ff;
    --vscode-editorMarkerNavigationInfo-headerBackground: rgba(26, 133, 255, 0.1);
    --vscode-editorMarkerNavigation-background: #ffffff;
    --vscode-editorSuggestWidget-background: #f7f8fa;
    --vscode-editorSuggestWidget-border: #ebecf0;
    --vscode-editorSuggestWidget-foreground: #000000;
    --vscode-editorSuggestWidget-selectedForeground: #000000;
    --vscode-editorSuggestWidget-selectedBackground: #cfdefc;
    --vscode-editorSuggestWidget-highlightForeground: #0066bf;
    --vscode-editorSuggestWidget-focusHighlightForeground: #0066bf;
    --vscode-editorSuggestWidgetStatus-foreground: rgba(0, 0, 0, 0.5);
    --vscode-editorWatermark-foreground: rgba(0, 0, 0, 0.68);
    --vscode-tab-activeBackground: #f9fef9;
    --vscode-tab-unfocusedActiveBackground: #f9fef9;
    --vscode-tab-inactiveBackground: #f7f8fa;
    --vscode-tab-unfocusedInactiveBackground: #f7f8fa;
    --vscode-tab-activeForeground: #000000;
    --vscode-tab-inactiveForeground: #777777;
    --vscode-tab-unfocusedActiveForeground: rgba(0, 0, 0, 0.7);
    --vscode-tab-unfocusedInactiveForeground: rgba(119, 119, 119, 0.5);
    --vscode-tab-hoverForeground: #000000;
    --vscode-tab-unfocusedHoverForeground: rgba(0, 0, 0, 0.5);
    --vscode-tab-border: #ebecf0;
    --vscode-tab-lastPinnedBorder: #aeb3c2;
    --vscode-tab-activeBorder: #005bc3;
    --vscode-tab-unfocusedActiveBorder: rgba(0, 91, 195, 0.7);
    --vscode-tab-dragAndDropBorder: #000000;
    --vscode-tab-activeModifiedBorder: #33aaee;
    --vscode-tab-inactiveModifiedBorder: rgba(51, 170, 238, 0.5);
    --vscode-tab-unfocusedActiveModifiedBorder: rgba(51, 170, 238, 0.7);
    --vscode-tab-unfocusedInactiveModifiedBorder: rgba(51, 170, 238, 0.25);
    --vscode-editorPane-background: #ffffff;
    --vscode-editorGroupHeader-tabsBackground: #f7f8fa;
    --vscode-editorGroupHeader-tabsBorder: #ebecf0;
    --vscode-editorGroupHeader-noTabsBackground: #f7f8fa;
    --vscode-editorGroup-border: #e7e7e7;
    --vscode-editorGroup-dropBackground: rgba(38, 119, 203, 0.18);
    --vscode-editorGroup-dropIntoPromptForeground: #616161;
    --vscode-editorGroup-dropIntoPromptBackground: #f7f8fa;
    --vscode-sideBySideEditor-horizontalBorder: #e7e7e7;
    --vscode-sideBySideEditor-verticalBorder: #e7e7e7;
    --vscode-panel-background: #f7f8fa;
    --vscode-panel-border: #ebecf0;
    --vscode-panelTitle-activeForeground: #424242;
    --vscode-panelTitle-inactiveForeground: rgba(66, 66, 66, 0.75);
    --vscode-panelTitle-activeBorder: #424242;
    --vscode-panelInput-border: #dddddd;
    --vscode-panel-dropBorder: #424242;
    --vscode-panelSection-dropBackground: rgba(38, 119, 203, 0.18);
    --vscode-panelSectionHeader-background: #f7f8fa;
    --vscode-panelSectionHeader-border: #ebecf0;
    --vscode-panelSection-border: #ebecf0;
    --vscode-banner-background: #4d85f4;
    --vscode-banner-foreground: #000000;
    --vscode-banner-iconForeground: #1a85ff;
    --vscode-statusBar-foreground: #000000;
    --vscode-statusBar-noFolderForeground: #000000;
    --vscode-statusBar-background: #f7f8fa;
    --vscode-statusBar-noFolderBackground: #f7f8fa;
    --vscode-statusBar-border: #ebecf0;
    --vscode-statusBar-focusBorder: #000000;
    --vscode-statusBar-noFolderBorder: #ebecf0;
    --vscode-statusBarItem-activeBackground: rgba(255, 255, 255, 0.18);
    --vscode-statusBarItem-focusBorder: #000000;
    --vscode-statusBarItem-hoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-hoverForeground: #000000;
    --vscode-statusBarItem-compactHoverBackground: rgba(255, 255, 255, 0.2);
    --vscode-statusBarItem-prominentForeground: #000000;
    --vscode-statusBarItem-prominentBackground: rgba(0, 0, 0, 0.5);
    --vscode-statusBarItem-prominentHoverForeground: #000000;
    --vscode-statusBarItem-prominentHoverBackground: rgba(0, 0, 0, 0.3);
    --vscode-statusBarItem-errorBackground: #611708;
    --vscode-statusBarItem-errorForeground: #ffffff;
    --vscode-statusBarItem-errorHoverForeground: #000000;
    --vscode-statusBarItem-errorHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-warningBackground: #725102;
    --vscode-statusBarItem-warningForeground: #ffffff;
    --vscode-statusBarItem-warningHoverForeground: #000000;
    --vscode-statusBarItem-warningHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-activityBar-background: #f7f8fa;
    --vscode-activityBar-foreground: #000000;
    --vscode-activityBar-inactiveForeground: rgba(0, 0, 0, 0.4);
    --vscode-activityBar-border: #ebecf0;
    --vscode-activityBar-activeBorder: #f7f8fa;
    --vscode-activityBar-dropBorder: #000000;
    --vscode-activityBarBadge-background: #007acc;
    --vscode-activityBarBadge-foreground: #ffffff;
    --vscode-activityBarTop-foreground: #424242;
    --vscode-activityBarTop-activeBorder: #424242;
    --vscode-activityBarTop-inactiveForeground: rgba(66, 66, 66, 0.75);
    --vscode-activityBarTop-dropBorder: #424242;
    --vscode-profileBadge-background: #c4c4c4;
    --vscode-profileBadge-foreground: #333333;
    --vscode-statusBarItem-remoteBackground: #f7f8fa;
    --vscode-statusBarItem-remoteForeground: #000000;
    --vscode-statusBarItem-remoteHoverForeground: #000000;
    --vscode-statusBarItem-remoteHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-statusBarItem-offlineBackground: #6c1717;
    --vscode-statusBarItem-offlineForeground: #000000;
    --vscode-statusBarItem-offlineHoverForeground: #000000;
    --vscode-statusBarItem-offlineHoverBackground: rgba(255, 255, 255, 0.12);
    --vscode-extensionBadge-remoteBackground: #007acc;
    --vscode-extensionBadge-remoteForeground: #ffffff;
    --vscode-sideBar-background: #f7f8fa;
    --vscode-sideBar-foreground: #000000;
    --vscode-sideBar-border: #ebecf0;
    --vscode-sideBarTitle-foreground: #000000;
    --vscode-sideBar-dropBackground: rgba(38, 119, 203, 0.18);
    --vscode-sideBarSectionHeader-background: #f7f8fa;
    --vscode-sideBarSectionHeader-foreground: #000000;
    --vscode-sideBarSectionHeader-border: #ebecf0;
    --vscode-sideBarActivityBarTop-border: #ebecf0;
    --vscode-titleBar-activeForeground: #e7ebed;
    --vscode-titleBar-inactiveForeground: rgba(231, 235, 237, 0.6);
    --vscode-titleBar-activeBackground: #8c53b2;
    --vscode-titleBar-inactiveBackground: #472160;
    --vscode-titleBar-border: #27282e;
    --vscode-menubar-selectionForeground: #e7ebed;
    --vscode-menubar-selectionBackground: rgba(184, 184, 184, 0.31);
    --vscode-commandCenter-foreground: #e7ebed;
    --vscode-commandCenter-activeForeground: #e7ebed;
    --vscode-commandCenter-inactiveForeground: rgba(231, 235, 237, 0.6);
    --vscode-commandCenter-background: #3369d6;
    --vscode-commandCenter-activeBackground: #2e5ec1;
    --vscode-commandCenter-border: #27282e;
    --vscode-commandCenter-activeBorder: #27282e;
    --vscode-commandCenter-inactiveBorder: #383a42;
    --vscode-notificationCenter-border: #ebecf0;
    --vscode-notificationToast-border: #ebecf0;
    --vscode-notifications-foreground: #616161;
    --vscode-notifications-background: #f7f8fa;
    --vscode-notificationLink-foreground: #006ab1;
    --vscode-notificationCenterHeader-background: #e8ebf0;
    --vscode-notifications-border: #e8ebf0;
    --vscode-notificationsErrorIcon-foreground: #e51400;
    --vscode-notificationsWarningIcon-foreground: #bf8803;
    --vscode-notificationsInfoIcon-foreground: #1a85ff;
    --vscode-chat-requestBorder: rgba(0, 0, 0, 0.1);
    --vscode-chat-requestBackground: rgba(255, 255, 255, 0.62);
    --vscode-chat-slashCommandBackground: #d2ecff;
    --vscode-chat-slashCommandForeground: #306ca2;
    --vscode-chat-avatarBackground: #f2f2f2;
    --vscode-chat-avatarForeground: #616161;
    --vscode-simpleFindWidget-sashBorder: #c8c8c8;
    --vscode-commentsView-resolvedIcon: rgba(97, 97, 97, 0.5);
    --vscode-commentsView-unresolvedIcon: #dfdfdf;
    --vscode-editorCommentsWidget-replyInputBackground: #f3f3f3;
    --vscode-editorCommentsWidget-resolvedBorder: rgba(97, 97, 97, 0.5);
    --vscode-editorCommentsWidget-unresolvedBorder: #dfdfdf;
    --vscode-editorCommentsWidget-rangeBackground: rgba(223, 223, 223, 0.1);
    --vscode-editorCommentsWidget-rangeActiveBackground: rgba(223, 223, 223, 0.1);
    --vscode-editorGutter-commentRangeForeground: #d4d4d4;
    --vscode-editorOverviewRuler-commentForeground: #d4d4d4;
    --vscode-editorOverviewRuler-commentUnresolvedForeground: #d4d4d4;
    --vscode-editorGutter-commentGlyphForeground: #000000;
    --vscode-editorGutter-commentUnresolvedGlyphForeground: #000000;
    --vscode-debugToolBar-background: #f7f8fa;
    --vscode-debugIcon-startForeground: #388a34;
    --vscode-editor-stackFrameHighlightBackground: rgba(255, 255, 102, 0.45);
    --vscode-editor-focusedStackFrameHighlightBackground: rgba(206, 231, 206, 0.45);
    --vscode-mergeEditor-change-background: rgba(155, 185, 85, 0.2);
    --vscode-mergeEditor-change-word-background: rgba(156, 204, 44, 0.4);
    --vscode-mergeEditor-changeBase-background: #ffcccc;
    --vscode-mergeEditor-changeBase-word-background: #ffa3a3;
    --vscode-mergeEditor-conflict-unhandledUnfocused-border: #ffa600;
    --vscode-mergeEditor-conflict-unhandledFocused-border: #ffa600;
    --vscode-mergeEditor-conflict-handledUnfocused-border: rgba(134, 134, 134, 0.29);
    --vscode-mergeEditor-conflict-handledFocused-border: rgba(193, 193, 193, 0.8);
    --vscode-mergeEditor-conflict-handled-minimapOverViewRuler: rgba(173, 172, 168, 0.93);
    --vscode-mergeEditor-conflict-unhandled-minimapOverViewRuler: #fcba03;
    --vscode-mergeEditor-conflictingLines-background: rgba(255, 234, 0, 0.28);
    --vscode-mergeEditor-conflict-input1-background: rgba(64, 200, 174, 0.2);
    --vscode-mergeEditor-conflict-input2-background: rgba(64, 166, 255, 0.2);
    --vscode-settings-headerForeground: #444444;
    --vscode-settings-settingsHeaderHoverForeground: rgba(68, 68, 68, 0.7);
    --vscode-settings-modifiedItemIndicator: #66afe0;
    --vscode-settings-headerBorder: #ebecf0;
    --vscode-settings-sashBorder: #ebecf0;
    --vscode-settings-dropdownBackground: #ffffff;
    --vscode-settings-dropdownForeground: #616161;
    --vscode-settings-dropdownBorder: #cecece;
    --vscode-settings-dropdownListBorder: #ebecf0;
    --vscode-settings-checkboxBackground: #ffffff;
    --vscode-settings-checkboxForeground: #616161;
    --vscode-settings-checkboxBorder: #cecece;
    --vscode-settings-textInputBackground: #ffffff;
    --vscode-settings-textInputForeground: #616161;
    --vscode-settings-numberInputBackground: #ffffff;
    --vscode-settings-numberInputForeground: #616161;
    --vscode-settings-focusedRowBackground: rgba(223, 223, 223, 0.6);
    --vscode-settings-rowHoverBackground: rgba(223, 223, 223, 0.3);
    --vscode-settings-focusedRowBorder: #aeb3c2;
    --vscode-terminal-background: #ffffff;
    --vscode-terminal-foreground: #000000;
    --vscode-terminal-selectionBackground: #a6d2ff;
    --vscode-terminal-inactiveSelectionBackground: rgba(166, 210, 255, 0.5);
    --vscode-terminalCommandDecoration-defaultBackground: rgba(0, 0, 0, 0.25);
    --vscode-terminalCommandDecoration-successBackground: #2090d3;
    --vscode-terminalCommandDecoration-errorBackground: #e51400;
    --vscode-terminalOverviewRuler-cursorForeground: rgba(160, 160, 160, 0.8);
    --vscode-terminal-border: #ebecf0;
    --vscode-terminal-findMatchBackground: #a6d2ff;
    --vscode-terminal-hoverHighlightBackground: rgba(173, 214, 255, 0.07);
    --vscode-terminal-findMatchHighlightBackground: rgba(252, 189, 56, 0.4);
    --vscode-terminalOverviewRuler-findMatchForeground: rgba(209, 134, 22, 0.49);
    --vscode-terminal-dropBackground: rgba(38, 119, 203, 0.18);
    --vscode-terminal-tab-activeBorder: #005bc3;
    --vscode-terminalStickyScrollHover-background: #f0f0f0;
    --vscode-testing-iconFailed: #f14c4c;
    --vscode-testing-iconErrored: #f14c4c;
    --vscode-testing-iconPassed: #73c991;
    --vscode-testing-runAction: #73c991;
    --vscode-testing-iconQueued: #cca700;
    --vscode-testing-iconUnset: #848484;
    --vscode-testing-iconSkipped: #848484;
    --vscode-testing-peekBorder: #e51400;
    --vscode-testing-messagePeekBorder: #1a85ff;
    --vscode-testing-peekHeaderBackground: rgba(229, 20, 0, 0.1);
    --vscode-testing-messagePeekHeaderBackground: rgba(26, 133, 255, 0.1);
    --vscode-testing-coveredBackground: rgba(156, 204, 44, 0.25);
    --vscode-testing-coveredBorder: rgba(156, 204, 44, 0.19);
    --vscode-testing-coveredGutterBackground: rgba(156, 204, 44, 0.15);
    --vscode-testing-uncoveredBranchBackground: #ff9999;
    --vscode-testing-uncoveredBackground: rgba(255, 0, 0, 0.2);
    --vscode-testing-uncoveredBorder: rgba(255, 0, 0, 0.15);
    --vscode-testing-uncoveredGutterBackground: rgba(255, 0, 0, 0.3);
    --vscode-testing-coverCountBadgeBackground: #c4c4c4;
    --vscode-testing-coverCountBadgeForeground: #333333;
    --vscode-testing-message-error-decorationForeground: #e51400;
    --vscode-testing-message-error-lineBackground: rgba(255, 0, 0, 0.2);
    --vscode-testing-message-info-decorationForeground: rgba(0, 0, 0, 0.5);
    --vscode-testing-iconErrored-retired: rgba(241, 76, 76, 0.7);
    --vscode-testing-iconFailed-retired: rgba(241, 76, 76, 0.7);
    --vscode-testing-iconPassed-retired: rgba(115, 201, 145, 0.7);
    --vscode-testing-iconQueued-retired: rgba(204, 167, 0, 0.7);
    --vscode-testing-iconUnset-retired: rgba(132, 132, 132, 0.7);
    --vscode-testing-iconSkipped-retired: rgba(132, 132, 132, 0.7);
    --vscode-welcomePage-tileBackground: #f7f8fa;
    --vscode-welcomePage-tileHoverBackground: #d9dde7;
    --vscode-welcomePage-tileBorder: rgba(0, 0, 0, 0.1);
    --vscode-welcomePage-progress-background: #ffffff;
    --vscode-welcomePage-progress-foreground: #006ab1;
    --vscode-walkthrough-stepTitle-foreground: #000000;
    --vscode-walkThrough-embeddedEditorBackground: #f4f4f4;
    --vscode-inlineChat-background: #f7f8fa;
    --vscode-inlineChat-border: #ebecf0;
    --vscode-inlineChat-shadow: rgba(0, 0, 0, 0.16);
    --vscode-inlineChat-regionHighlight: rgba(173, 214, 255, 0.15);
    --vscode-inlineChatInput-border: #ebecf0;
    --vscode-inlineChatInput-focusBorder: #aeb3c2;
    --vscode-inlineChatInput-placeholderForeground: rgba(97, 97, 97, 0.5);
    --vscode-inlineChatInput-background: #ffffff;
    --vscode-inlineChatDiff-inserted: rgba(156, 204, 44, 0.13);
    --vscode-editorOverviewRuler-inlineChatInserted: rgba(156, 204, 44, 0.2);
    --vscode-inlineChatDiff-removed: rgba(255, 0, 0, 0.1);
    --vscode-editorOverviewRuler-inlineChatRemoved: rgba(255, 0, 0, 0.16);
    --vscode-debugExceptionWidget-border: #a31515;
    --vscode-debugExceptionWidget-background: #f1dfde;
    --vscode-statusBar-debuggingBackground: #5eb563;
    --vscode-statusBar-debuggingForeground: #000000;
    --vscode-statusBar-debuggingBorder: #ebecf0;
    --vscode-commandCenter-debuggingBackground: rgba(94, 181, 99, 0.26);
    --vscode-editorGutter-modifiedBackground: #2090d3;
    --vscode-editorGutter-addedBackground: #48985d;
    --vscode-editorGutter-deletedBackground: #e51400;
    --vscode-minimapGutter-modifiedBackground: #2090d3;
    --vscode-minimapGutter-addedBackground: #48985d;
    --vscode-minimapGutter-deletedBackground: #e51400;
    --vscode-editorOverviewRuler-modifiedForeground: rgba(32, 144, 211, 0.6);
    --vscode-editorOverviewRuler-addedForeground: rgba(72, 152, 93, 0.6);
    --vscode-editorOverviewRuler-deletedForeground: rgba(229, 20, 0, 0.6);
    --vscode-keybindingTable-headerBackground: rgba(97, 97, 97, 0.04);
    --vscode-keybindingTable-rowsBackground: rgba(97, 97, 97, 0.04);
    --vscode-debugIcon-breakpointForeground: #e51400;
    --vscode-debugIcon-breakpointDisabledForeground: #848484;
    --vscode-debugIcon-breakpointUnverifiedForeground: #848484;
    --vscode-debugIcon-breakpointCurrentStackframeForeground: #be8700;
    --vscode-debugIcon-breakpointStackframeForeground: #89d185;
    --vscode-editor-inlineValuesForeground: rgba(0, 0, 0, 0.5);
    --vscode-editor-inlineValuesBackground: rgba(255, 200, 0, 0.2);
    --vscode-ports-iconRunningProcessForeground: #f7f8fa;
    --vscode-debugTokenExpression-name: #9b46b0;
    --vscode-debugTokenExpression-value: rgba(108, 108, 108, 0.8);
    --vscode-debugTokenExpression-string: #a31515;
    --vscode-debugTokenExpression-boolean: #0000ff;
    --vscode-debugTokenExpression-number: #098658;
    --vscode-debugTokenExpression-error: #e51400;
    --vscode-debugView-exceptionLabelForeground: #ffffff;
    --vscode-debugView-exceptionLabelBackground: #a31515;
    --vscode-debugView-stateLabelForeground: #616161;
    --vscode-debugView-stateLabelBackground: rgba(136, 136, 136, 0.27);
    --vscode-debugView-valueChangedHighlight: #569cd6;
    --vscode-debugConsole-infoForeground: #1a85ff;
    --vscode-debugConsole-warningForeground: #bf8803;
    --vscode-debugConsole-errorForeground: #a1260d;
    --vscode-debugConsole-sourceForeground: #616161;
    --vscode-debugConsoleInputIcon-foreground: #616161;
    --vscode-debugIcon-pauseForeground: #007acc;
    --vscode-debugIcon-stopForeground: #a1260d;
    --vscode-debugIcon-disconnectForeground: #a1260d;
    --vscode-debugIcon-restartForeground: #388a34;
    --vscode-debugIcon-stepOverForeground: #007acc;
    --vscode-debugIcon-stepIntoForeground: #007acc;
    --vscode-debugIcon-stepOutForeground: #007acc;
    --vscode-debugIcon-continueForeground: #007acc;
    --vscode-debugIcon-stepBackForeground: #007acc;
    --vscode-notebook-cellBorderColor: #dfdfdf;
    --vscode-notebook-focusedEditorBorder: #aeb3c2;
    --vscode-notebookStatusSuccessIcon-foreground: #388a34;
    --vscode-notebookEditorOverviewRuler-runningCellForeground: #388a34;
    --vscode-notebookStatusErrorIcon-foreground: #a1260d;
    --vscode-notebookStatusRunningIcon-foreground: #616161;
    --vscode-notebook-cellToolbarSeparator: rgba(128, 128, 128, 0.35);
    --vscode-notebook-selectedCellBackground: #dfdfdf;
    --vscode-notebook-selectedCellBorder: #dfdfdf;
    --vscode-notebook-focusedCellBorder: #aeb3c2;
    --vscode-notebook-inactiveFocusedCellBorder: #dfdfdf;
    --vscode-notebook-cellStatusBarItemHoverBackground: rgba(0, 0, 0, 0.08);
    --vscode-notebook-cellInsertionIndicator: #aeb3c2;
    --vscode-notebookScrollbarSlider-background: rgba(58, 58, 58, 0.2);
    --vscode-notebookScrollbarSlider-hoverBackground: rgba(100, 100, 100, 0.7);
    --vscode-notebookScrollbarSlider-activeBackground: rgba(0, 0, 0, 0.6);
    --vscode-notebook-symbolHighlightBackground: rgba(253, 255, 0, 0.2);
    --vscode-notebook-cellEditorBackground: #f7f8fa;
    --vscode-notebook-editorBackground: #ffffff;
    --vscode-interactive-activeCodeBorder: #007acc;
    --vscode-interactive-inactiveCodeBorder: #dfdfdf;
    --vscode-scm-historyItemAdditionsForeground: #3e7718;
    --vscode-scm-historyItemDeletionsForeground: #ad0707;
    --vscode-scm-historyItemStatisticsBorder: rgba(97, 97, 97, 0.2);
    --vscode-scm-historyItemSelectedStatisticsBorder: rgba(0, 0, 0, 0.2);
    --vscode-extensionButton-background: #007acc;
    --vscode-extensionButton-foreground: #ffffff;
    --vscode-extensionButton-hoverBackground: #0062a3;
    --vscode-extensionButton-separator: rgba(255, 255, 255, 0.4);
    --vscode-extensionButton-prominentBackground: #007acc;
    --vscode-extensionButton-prominentForeground: #ffffff;
    --vscode-extensionButton-prominentHoverBackground: #0062a3;
    --vscode-extensionIcon-starForeground: #df6100;
    --vscode-extensionIcon-verifiedForeground: #006ab1;
    --vscode-extensionIcon-preReleaseForeground: #1d9271;
    --vscode-extensionIcon-sponsorForeground: #b51e78;
    --vscode-terminal-ansiBlack: #6e6e6e;
    --vscode-terminal-ansiRed: #d70000;
    --vscode-terminal-ansiGreen: #5f8700;
    --vscode-terminal-ansiYellow: #af8700;
    --vscode-terminal-ansiBlue: #0087ff;
    --vscode-terminal-ansiMagenta: #af005f;
    --vscode-terminal-ansiCyan: #00afaf;
    --vscode-terminal-ansiWhite: #e4e4e4;
    --vscode-terminal-ansiBrightBlack: #1c1c1c;
    --vscode-terminal-ansiBrightRed: #d75f00;
    --vscode-terminal-ansiBrightGreen: #585858;
    --vscode-terminal-ansiBrightYellow: #626262;
    --vscode-terminal-ansiBrightBlue: #808080;
    --vscode-terminal-ansiBrightMagenta: #5f5faf;
    --vscode-terminal-ansiBrightCyan: #8a8a8a;
    --vscode-terminal-ansiBrightWhite: #ffffd7;
    --vscode-gitDecoration-addedResourceForeground: #3e7718;
    --vscode-gitDecoration-modifiedResourceForeground: #0050c1;
    --vscode-gitDecoration-deletedResourceForeground: #ad0707;
    --vscode-gitDecoration-renamedResourceForeground: #007100;
    --vscode-gitDecoration-untrackedResourceForeground: #007100;
    --vscode-gitDecoration-ignoredResourceForeground: #8e8e90;
    --vscode-gitDecoration-stageModifiedResourceForeground: #895503;
    --vscode-gitDecoration-stageDeletedResourceForeground: #ad0707;
    --vscode-gitDecoration-conflictingResourceForeground: #ad0707;
    --vscode-gitDecoration-submoduleResourceForeground: #1258a7;
    --vscode-bookmarks-lineBackground: rgba(0, 0, 0, 0);
    --vscode-bookmarks-lineBorder: rgba(0, 0, 0, 0);
    --vscode-bookmarks-overviewRuler: rgba(21, 126, 251, 0.53);
    --vscode-gitlens-gutterBackgroundColor: rgba(0, 0, 0, 0.05);
    --vscode-gitlens-gutterForegroundColor: #747474;
    --vscode-gitlens-gutterUncommittedForegroundColor: rgba(0, 188, 242, 0.6);
    --vscode-gitlens-trailingLineBackgroundColor: rgba(0, 0, 0, 0);
    --vscode-gitlens-trailingLineForegroundColor: rgba(153, 153, 153, 0.35);
    --vscode-gitlens-lineHighlightBackgroundColor: rgba(0, 188, 242, 0.2);
    --vscode-gitlens-lineHighlightOverviewRulerColor: rgba(0, 188, 242, 0.6);
    --vscode-gitlens-openAutolinkedIssueIconColor: #1a7f37;
    --vscode-gitlens-closedAutolinkedIssueIconColor: #8250df;
    --vscode-gitlens-closedPullRequestIconColor: #cf222e;
    --vscode-gitlens-openPullRequestIconColor: #1a7f37;
    --vscode-gitlens-mergedPullRequestIconColor: #8250df;
    --vscode-gitlens-unpublishedChangesIconColor: #35b15e;
    --vscode-gitlens-unpublishedCommitIconColor: #35b15e;
    --vscode-gitlens-unpulledChangesIconColor: #b15e35;
    --vscode-gitlens-decorations-addedForegroundColor: #3e7718;
    --vscode-gitlens-decorations-copiedForegroundColor: #007100;
    --vscode-gitlens-decorations-deletedForegroundColor: #ad0707;
    --vscode-gitlens-decorations-ignoredForegroundColor: #8e8e90;
    --vscode-gitlens-decorations-modifiedForegroundColor: #0050c1;
    --vscode-gitlens-decorations-untrackedForegroundColor: #007100;
    --vscode-gitlens-decorations-renamedForegroundColor: #007100;
    --vscode-gitlens-decorations-branchAheadForegroundColor: #35b15e;
    --vscode-gitlens-decorations-branchBehindForegroundColor: #b15e35;
    --vscode-gitlens-decorations-branchDivergedForegroundColor: #d8af1b;
    --vscode-gitlens-decorations-branchUpToDateForegroundColor: #000000;
    --vscode-gitlens-decorations-branchUnpublishedForegroundColor: #35b15e;
    --vscode-gitlens-decorations-branchMissingUpstreamForegroundColor: #ad0707;
    --vscode-gitlens-decorations-statusMergingOrRebasingConflictForegroundColor: #ad0707;
    --vscode-gitlens-decorations-statusMergingOrRebasingForegroundColor: #d8af1b;
    --vscode-gitlens-decorations-workspaceRepoMissingForegroundColor: #949494;
    --vscode-gitlens-decorations-workspaceCurrentForegroundColor: #35b15e;
    --vscode-gitlens-decorations-workspaceRepoOpenForegroundColor: #35b15e;
    --vscode-gitlens-decorations-worktreeHasUncommittedChangesForegroundColor: #895503;
    --vscode-gitlens-decorations-worktreeMissingForegroundColor: #ad0707;
    --vscode-gitlens-graphLane1Color: #15a0bf;
    --vscode-gitlens-graphLane2Color: #0669f7;
    --vscode-gitlens-graphLane3Color: #8e00c2;
    --vscode-gitlens-graphLane4Color: #c517b6;
    --vscode-gitlens-graphLane5Color: #d90171;
    --vscode-gitlens-graphLane6Color: #cd0101;
    --vscode-gitlens-graphLane7Color: #f25d2e;
    --vscode-gitlens-graphLane8Color: #f2ca33;
    --vscode-gitlens-graphLane9Color: #7bd938;
    --vscode-gitlens-graphLane10Color: #2ece9d;
    --vscode-gitlens-graphChangesColumnAddedColor: #2da44e;
    --vscode-gitlens-graphChangesColumnDeletedColor: #cf222e;
    --vscode-gitlens-graphMinimapMarkerHeadColor: #04c814;
    --vscode-gitlens-graphScrollMarkerHeadColor: #04c814;
    --vscode-gitlens-graphMinimapMarkerUpstreamColor: #8cd993;
    --vscode-gitlens-graphScrollMarkerUpstreamColor: #8cd993;
    --vscode-gitlens-graphMinimapMarkerHighlightsColor: #f5cc00;
    --vscode-gitlens-graphScrollMarkerHighlightsColor: #f5cc00;
    --vscode-gitlens-graphMinimapMarkerLocalBranchesColor: #3095e8;
    --vscode-gitlens-graphScrollMarkerLocalBranchesColor: #3095e8;
    --vscode-gitlens-graphMinimapMarkerRemoteBranchesColor: #67ace4;
    --vscode-gitlens-graphScrollMarkerRemoteBranchesColor: #67ace4;
    --vscode-gitlens-graphMinimapMarkerStashesColor: #e467e4;
    --vscode-gitlens-graphScrollMarkerStashesColor: #e467e4;
    --vscode-gitlens-graphMinimapMarkerTagsColor: #d2a379;
    --vscode-gitlens-graphScrollMarkerTagsColor: #d2a379;
    --vscode-issues-newIssueDecoration: rgba(0, 0, 0, 0.28);
    --vscode-issues-open: #3fb950;
    --vscode-issues-closed: #cb2431;
    --vscode-pullRequests-merged: #8957e5;
    --vscode-pullRequests-draft: #6e7681;
    --vscode-pullRequests-open: #3fb950;
    --vscode-pullRequests-closed: #cb2431;
    --vscode-pullRequests-notification: #1a85ff;
    --vscode-rust_analyzer-syntaxTreeBorder: #b700ff;
}